const SEARCH_PARAMS = {
  FROM_SEARCH: 'from-search',
  PRELOAD_SEARCH: 'preload-search'
};

const updateUrlParameter = (key, value) => {
  const origUrl = window.location.href;
  window.history.replaceState('', '', updateParam(origUrl, key, value));
};

const updateParam = (url, param, value) => {
  const re = new RegExp(param + '(.+?)(&|$)', 'g');

  if (!url.match(re, url)) {
    return url + (url.match('\\?') ? '&' : '?') + param + '=' + value;
  }

  return url.replace(re, param + '=' + value + '$2');
};

const removeUrlParameter = (key) => {
  if (window.history.pushState) {
    const windowUrl = window.location.href;
    const url = new URL(windowUrl);
    url.searchParams.delete(key);
    const newUrl = url.href;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

const getUrlParam = (key) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
};

const hasUrlParam = (key) => {
  const url = new URL(window.location.href);
  return url.searchParams.has(key);
};

export {
  SEARCH_PARAMS,
  hasUrlParam,
  getUrlParam,
  removeUrlParameter,
  updateUrlParameter
};
